<template>
    <div class="page-box">
      <div class="filter-box" style="display: flex">
        <SearchInput
            :search.sync="search"
            placeholder="请输入会员ID/手机号/IP"
            @search="searchData"
        />
        <el-date-picker
            format="yyyy-MM-dd"
            v-model="dataRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right">
        </el-date-picker>
        <div class="m-l-15">
            <el-select v-model="type" placeholder="类型" @change="init" class="select">
                <el-option label="全部" value="">全部</el-option>
                <el-option label="登录注册" :value="1">登录注册</el-option>
                <el-option label="忘记密码" :value="2">忘记密码</el-option>
                <el-option label="注销账号" :value="3">注销账号</el-option>
                <el-option label="换绑手机" :value="4">换绑手机</el-option>
                <el-option label="更换密码" :value="5">更换密码</el-option>
            </el-select>
        </div>
      </div>
      <div class="table">
        <el-table :data="tableData"
                  :row-style="{height:'40px'}" :header-row-style="{height:'40px'}" border
                  height="100%"
                  style="width: 100%">
          <el-table-column align="center" prop="member_id" label="会员ID"></el-table-column>
          <el-table-column align="center" prop="mobile" label="手机号">
            <template slot-scope="scope">
              <span v-if="isAdmin">{{  scope.row.mobile }}</span>
              <span v-else>{{  scope.row.mobile | hidePhone }}</span>
            </template>
    
          </el-table-column>
          <el-table-column align="center" prop="content" label="信息内容"></el-table-column>
          <el-table-column align="center" prop="create_time" label="发送时间"></el-table-column>
          <el-table-column align="center" prop="operator" label="运营商"></el-table-column>
          <table-one-empty align="center" prop="channel" label="渠道" />
          <el-table-column align="center" prop="package_name" label="包名"></el-table-column>
          <el-table-column align="center" prop="ip" label="IP" width="250"></el-table-column>
          <el-table-column align="center" prop="type" label="类型">
            <template slot-scope="scope">
                {{ scope.row.type === 1 ? '登录注册'
                    :scope.row.type === 2 ? '忘记密码'
                        :scope.row.type === 3 ? '注销账号'
                            :scope.row.type === 4 ? '换绑手机'
                                :scope.row.type === 5 ? '更换密码' : '-'
                }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="status" label="状态">
            <template slot-scope="scope">
              <span :style="{color: scope.row.status === 1 ? '#67C23A' : '#F56C6C'}">
                {{ scope.row.status === 1 ? '成功'  : '失败'}}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination block">
        <pagination
            :current-page.sync="pagination.currentPage"
            :page-size.sync="pagination.pageSize"
            :total="pagination.total"
            @loadData="init"
        />
      </div>
    </div>
    </template>
    
    <script>
    import { getCodeHistory } from "@/api/content";
    import SearchInput from "@components/common/SearchInput";
    import Pagination from "@/pages/Layout/Pagination";
    import {statusCode} from "@/util/statusCode";
    import {Format, MillisecondToTime} from "@/util/common";
    import { mapState } from 'vuex'
    import TableOneEmpty from '@components/common/tableOneEmpty.vue'
    import dayjs from 'dayjs';
    export default {
      name: "ValidateCode",
      components: {
        SearchInput,
        Pagination,
        TableOneEmpty
      },
      data() {
        return {
          list: [],
          pagination: {
            currentPage: 1,
            pageSize: 20,
            total: 0
          },
          search: '',
          type: '',
          dataRange: '',
          loading: true,
          tableData: [],
        }
      },
      filters: {
        hidePhone(phone) {
          return phone.replace(/^(\d{3})\d{4}(\d+)/, '$1****$2');
        }
      },
      computed: {
        isAdmin() {
          return this.$store.state.userInfo.account === 'admin'
        },
        apiParams() {
          return {
            pageSize: this.pagination.pageSize,
            pageNum: this.pagination.currentPage,
            search: this.search,
            type: this.type,
            start_date: this.dataRange && this.dataRange[0] ? dayjs(this.dataRange[0]).format('YYYY-MM-DD') : '',
            end_date: this.dataRange && this.dataRange[1] ? dayjs(this.dataRange[1]).format('YYYY-MM-DD') : '',
          }
        }
      },
      watch:{
        dataRange(newVal, oldVal) {
          this.pagination.pageSize = 20
          this.pagination.currentPage = 1
          this.init()
        }
      },
      created() {
        this.init()
      },
      methods: {
        async init() {
          this.loading = true
          try {
            // if(this.apiParams.end_time){
            //   let end = this.apiParams.end_time
            //   this.apiParams.end_time = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59)
            // }
            let {data} = await getCodeHistory(this.apiParams)
            if (data.code === statusCode.success) {
              const _data = data.data
              this.pagination.total = _data.total
              this.tableData = JSON.parse(JSON.stringify(_data.data))
              this.tableData.map((item, i) => {
                item.start_time = Format(item.start_time, '-')
                item.end_time = Format(item.end_time, '-')
                item.live_time = MillisecondToTime(item.live_time)
              })
              this.loading = false
            }
          } catch (e) {
            console.log('error--error')
          }
        },
        setSellStyle({row, column, rowIndex, columnIndex}) {
          if (columnIndex == 0) return "borderRadius: 10px  0 0 10px"
          if (columnIndex == 7) return "borderRadius: 0  10px 10px 0"
        },
    
        searchData() {
          this.pagination.pageSize = 20
          this.pagination.currentPage = 1
          this.init()
        },
      }
    }
    </script>
    
    <style lang="scss" scoped>
    .page-box {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    
      .filter-box {
        margin-bottom: 20px;
      }
    
      .pagination {
        margin-top: 10px;
      }
    
      .table {
        height: calc(100% - 100px);
      }
    }
    
    
    </style>
    